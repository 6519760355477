
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'memberGrowup',
  components: {
  },
  mixins: [GlobalMixin]
})
export default class memberGrowup extends Vue {
  @Provide() public list: any = []
  @Provide() public testList: any = {}
  @Provide() public info: any = {}

  public created () {
    this.refresh()
  }

  private refresh () {
    this.levelList()
    this.userInfo()
  }

  @Emit()
  private async levelList () {
    try {
      const res = await httpHelper.get({
        url: 'IUser/Member',
        type: 'apiv5'
      })
      this.info = res.data
      this.list = res.data.levelList
      const spans = this.list.length - 1
      const CurrentTotalGrowth = this.info.currentTotalGrowth
      const CurrentLevel = this.info.currentLevel
    } catch (err) {
      this.$toasted.show(err.msg)
    }
  }

  @Emit()
  private async userInfo () {
    try {
      const res = await httpHelper.get({
        url: 'IUser/GrowthDescription',
        type: 'apiv5'
      })
      this.testList = res.data
    } catch (err) {
      this.$toasted.show(err.msg)
    }
  }

  get width () {
    const spans = this.list.length - 1
    const CurrentTotalGrowth = this.info.currentTotalGrowth
    const CurrentLevel = this.info.currentLevel
    let currentIndex = 0
    let floorGrowth = 0
    let TopGrowth = 0
    this.list.forEach((item: any, index: number) => {
      if (CurrentLevel === item.levelSysNo) {
        currentIndex = index
        floorGrowth = item.levelSysNo
        TopGrowth = item.topGrowth
      }
    })
    const width = 'width:' + (100 - ((100 / spans) * currentIndex)) + '%;'
    const longwidth = 'width:' + (100 - ((100 / spans) * currentIndex + 100 / spans * ((CurrentTotalGrowth - floorGrowth) / (TopGrowth - floorGrowth)))) + '%;'
    if (CurrentTotalGrowth - floorGrowth === 0 || TopGrowth - floorGrowth === 0) {
      return width
    } else {
      return longwidth
    }
  }

  @Emit()
  private goBack () {
    window.history.back()
  }
}

