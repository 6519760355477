
import { Component, Vue, Provide, Emit, Prop, Watch } from 'vue-property-decorator'
import { mobileRegExp } from '@/utils/reg'
import smsMixin from '@/mixins/sms'
import httpHelper from '@/utils/request'
@Component({
  name: 'InputItem',
  components: {
  },
  mixins: [smsMixin]
})

export default class InputItem extends Vue {
  public name = 'InputItem'

  @Prop({
    type: Number,
    default: 50
  }) maxLength!: number

  @Prop({
    type: String,
    default: 'text'
  }) inputType!: string

  @Prop({
    type: String,
    default: ''
  }) isWeight!: string

  @Prop({
    type: String,
    default: ''
  }) placeholder!: string

  @Prop({
    type: String,
    default: ''
  }) mobile!: string

  @Provide() public value: string = ''
  @Provide() public isFocus: boolean = false

  public created () {
    console.log(this.mobile)
    this.value = this.mobile
  }

  @Emit()
  public clearvalue () {
    this.value = ''
    this.$emit('setValue', {
      value: this.value
    })
  }

  @Emit()
  public keyup () {
    if (this.inputType === 'tel' || this.inputType === 'number') {
      this.value = isNaN(parseInt(this.value)) ? '' : String(parseInt(this.value))
    }
    this.$emit('setValue', {
      value: this.value
    })
  }

  @Emit()
  public blur () {
    const self = this
    setTimeout(() => {
      self.isFocus = false
    }, 300)
  }
}
