
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import InputItem from '@/components/InputItem.vue'
import smsMixin from '@/mixins/sms'
import { codeRegExp, emailRegExp } from '@/utils/reg'
import GlobalMixin from '@/mixins/global'
import randomString from '@/utils/random'
import { getGoBackUrl, removeGoBackUrl } from '@/utils/gobackurl'
const CryptoJS = require('crypto-js')

@Component({
  name: 'passportFindAccount',
  components: {
    'input-item': InputItem
  },
  mixins: [smsMixin, GlobalMixin]
})
export default class PassportFindAccountPage extends Vue {
  @Provide() public account: string = ''
  @Provide() public code: number = 0
  @Provide() public waitTime: number = 2
  @Provide() public load: boolean = true
  @Provide() public apiError: string = ''

  @Emit()
  public created () {
    this.refresh()
  }

  @Emit()
  public refresh () {
  }

  @Emit()
  public emailError () {
    if (!this.account) {
      return '邮箱不能为空'
    }
    return emailRegExp.test(this.account) ? '' : '您输入的邮箱格式不正确，请重新输入'
  }

  @Emit()
  public codeError () {
    if (!this.code) {
      return '验证码不能为空'
    }
    return codeRegExp.test(String(this.code)) ? '' : '您输入的验证码格式不正确，请重新输入'
  }

  @Emit()
  public setAccount (detail: any) {
    this.account = detail.value
  }

  @Emit()
  public setCode (detail: any) {
    this.code = detail.value
  }

  @Emit()
  public async sendCode () {
    this.apiError = this.emailError()
    if (this.apiError) {
      return
    }
    let data: any = {
      EmailType: 4
    }
    let iv = randomString(16)
    const time = Math.round(Date.now() / 1000)
    const message = `${this.account}|${time}`
    const key = CryptoJS.enc.Utf8.parse('k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav')
    data.salt = iv
    iv = CryptoJS.enc.Utf8.parse(iv)
    const encrypted = CryptoJS.AES.encrypt(message,
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })
    data.email = encrypted.toString()
    try {
      const res = await httpHelper.post({
        url: 'SMS/EmailCode',
        data,
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.$toasted.show('邮件发送成功！')
      }
    } catch (err) {
      this.showErrorToast(err)
    }
  }

  @Emit()
  public async submit () {
    this.apiError = this.emailError() || this.codeError()
    if (this.apiError) {
      return
    }
    if (!this.load) {
      return
    }
    this.load = false
    let data: any = {
      code: this.code
    }
    let iv = randomString(16)
    const time = Math.round(Date.now() / 1000)
    const message = `${this.account}|${time}`
    const key = CryptoJS.enc.Utf8.parse('k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav')
    data.salt = iv
    iv = CryptoJS.enc.Utf8.parse(iv)
    const encrypted = CryptoJS.AES.encrypt(message,
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })
    data.email = encrypted.toString()
    try {
      const res = await httpHelper.post({
        url: 'IAccount/BindEmail',
        data,
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.$toasted.show('邮箱设置成功！' + this.waitTime + '秒钟后自动返回首页').goAway(3000)
        this.getWaitTime()
      }
    } catch (err) {
      this.showErrorToast(err)
      this.load = true
    }
  }
  @Emit()
  public getWaitTime () {
    const self = this
    if (this.waitTime > 0) {
      setTimeout(() => {
        self.waitTime--
        self.getWaitTime()
      }, 1000)
    } else {
      let url = getGoBackUrl()
      if (document.referrer.includes('/user/index') || document.referrer.includes('userHome')) {
        console.log('goback2')
        location.replace(httpHelper.mRoot)
      } else if (document.referrer.includes('passport.benlai.com/authorization')) {
        console.log('goback3')
        history.go(-2)
      } else if (url.includes('/user/index') || url.includes('userHome')) {
        console.log('goback6')
        removeGoBackUrl()
        location.replace(httpHelper.mRoot)
      } else if (url) {
        console.log('goback5')
        removeGoBackUrl()
        location.replace(url)
      } else {
        console.log('goback4')
        history.back()
      }
    }
  }
  @Emit()
  public goback () {
    history.back()
  }
}
